<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
    <div class="d-block d-lg-none">
        <div class="row">
            <div class="col-10 mb-0 col-sm-9 col-lg-10">
                <p class="myfamilyMobile" style="margin-left: 20px;">My family</p>
            </div>
            <div class="col-2 col-sm-3 col-lg-2 ">
                <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
            </div>
        </div>
        <div class="hr-border"></div>
    </div>
    <div class="mobileStylePublic">
    <div class="row">
        <div class=" col-sm-6  col-md-4 col-12">
            <legend class="subHeadingText ">My family members</legend>
        </div>
        <div class="col-6 col-lg-4 col-md-4 d-none d-lg-block">
            <button class="expand-toggle-btn " @click="showMe()" role="button">{{ this.showFamily ? "Close all" : "Expand all" }}</button>
        </div>
    </div>
    
    <div class="d-none d-lg-block">
        <!-- Get Family desktop and Tablet view  -->
    <div class="content mt-5 " >
         <!-- Get User Data  -->
        <div class="three">
            <div class="top" >
                <div class="title" >{{ userRecords.firstName }}</div>
            </div>
            <a  href="#popupUpdate">
        <transition name="slide" class="list" >
        <div class="expandable"  v-if="showFamily" @click="getUserAndFamilyData(userRecords)" > 
                <div  >
            <div class="details">
          <div class="one">
            <span><strong>RxIx ID : </strong><span>{{ userRecords.id }} - 1</span></span>
          </div>
          <div class="one">
            <span><strong>First name : </strong><span>{{ userRecords.firstName }}</span></span>
          </div>
          <div class="one">
            <span><strong>Last name : </strong><span>{{ userRecords.lastName}}</span></span>
          </div>
          <div class="one">
            <span><strong>Age : </strong><span>{{ userRecords.age }}</span></span>
          </div>
          <div class="one">
            <span><strong>Gender : </strong><span>{{ userRecords.gender }}</span></span>
          </div>
          <div class="one">
            <span><strong>Relation : </strong><span>Self</span></span>
          </div>
          </div>
        </div>
    </div>
</transition>
</a>
    

</div>
    <!-- Get Family Data  -->
   <div class="three" v-for="(accordion, index) in famData" :key="index"> 
      <div class="top" >
        <div class="title" >{{ accordion.firstName }}</div>
      </div>
      <a  href="#popupUpdate">
    <transition name="slide" class="list">
            
      <div class="expandable"  v-if="showFamily" @click="getUserAndFamilyData(accordion)" >    
        <div  class="details" >
        <div class="one">
            <span><strong>RxIx ID : </strong>{{ accordion.users.id }} - {{ 1+ accordion.memberCount }} </span>
          </div>
          <div class="one">
            <span><strong>First name : </strong>{{ accordion.firstName }} </span>
          </div>
          <div class="one">
            <span><strong>Last name : </strong><span>{{ accordion.lastName }}</span></span>
          </div>
          <div class="one">
            <span><strong>Age : </strong><span>{{ accordion.age }} </span></span>
          </div>
          <div class="one">
            <span><strong>Gender : </strong><span>{{ accordion.gender }} </span></span>
          </div>
          <div class="one">
            <span><strong>Relation : </strong><span>{{ accordion.relation }} </span></span>
          </div>
        </div>
       </div>
    </transition>
</a>
      </div>
    </div>
</div>
            <!-- Get Family Mobile view  -->
            <div class="d-block d-lg-none p-2">
                
                <div class="accordion" id="accordionFlushExampleFam">
                    <!-- Get User Data  -->
                    <div class="accordion-item" style="border:none" >
                        <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed border-color" style="border-radius: 5px;" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                {{ userRecords.firstName }}
                </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                <fieldset class="curve-box-mobile">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-6">
                        <label>RxIx ID</label>
                        <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true" :value="getDisplayPublicValue(userRecords)"
                            @input="updateDisplayValue(index, $event)">
                    </div>
            </div>
            <div class="row my-2">
                <div class="col-12 col-md-6 col-lg-6 col-sm-6">
                    <label>First name</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true" v-model="userRecords.firstName">
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-sm-6">
                    <label>Last name</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true" v-model="userRecords.lastName">
                </div>
            </div>
            <div class="row my-2">
                <div class="col-6">
                    <label>Age</label>
                    <input type="number" class="form-control cursor UpdateTextAlignment" :disabled="true" v-model="userRecords.age">
                </div>
                <div class="col-6">
                    <label>Gender</label>
                    <input type="gender" class="form-control cursor UpdateTextAlignment" :disabled="true" v-model="userRecords.gender">
                </div>
            </div>
            <div class="row my-2">
                    <div class="col-12 col-md-6 col-lg-6">
                        <label>Relation</label>
                        <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                            value="Self">
                    </div>
                </div>
</fieldset>
</div>
</div>
</div>
    <!-- Get Family Data  -->
  <div class="accordion-item" style="border:none" v-for="(accordion, index) in famData" :key="index">
    <h2 class="accordion-header" :id="'flush-heading-fam' + index">
      <button class="accordion-button collapsed border-color" style="border-radius: 5px;" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse-fam' + index" :aria-expanded="activeAccordion === index ? 'true' : 'false'">
        {{ accordion.firstName }}
      </button>
    </h2>
    <div :id="'flush-collapse-fam' + index" class="accordion-collapse collapse" :aria-labelledby="'flush-heading-fam' + index" data-bs-parent="#accordionFlushExampleFam">
      <div class="accordion-body">
        <fieldset class="curve-box">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                    <label>RxIx ID</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                    :value="getDisplayValue(accordion)"
                    @input="updateDisplayValue(accordion, $event)"
                    >
                </div>
            </div>
            <div class="row my-2">
                <div class="col-12 col-md-6 col-lg-6">
                    <label>First name</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                        v-model="accordion.firstName">
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <label>Last name</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                        v-model="accordion.lastName">
                </div>
            </div>
            <div class="row my-2">
                <div class="col-6">
                    <label>Age</label>
                    <input type="number" class="form-control cursor UpdateTextAlignment" :disabled="true"
                        v-model="accordion.age">
                </div>
                <div class="col-6">
                    <label>Gender</label>
                    <input type="gender" class="form-control cursor UpdateTextAlignment" :disabled="true"
                        v-model="accordion.gender">
                </div>
            </div>
            <div class="row my-2">
                <div class="col-12 col-md-6 col-lg-6">
                    <label>Relation</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                        v-model="accordion.relation">
            </div>
        </div>
        <div>
        </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>

      </div>

                <!-- Create Family  -->
<div id="popup1" class="overlay" v-if="isAddNewMember">
	<div class="popup">
        <div>
        <div class="fam-title">
            <b>Add Family</b>
            <a class="close" @click="closeCreateForm">&times;</a>
        </div>
        <div class="fam-popup">
<div class="row">
            <div class="col-12 ">
                <div class="form-group my-1">
                    <strong >First name<span class="star">*</span></strong>
                    <input type="text" class="form-control" v-model="firstName">
                </div>
            </div>
            <div class="col-12 ">
                <div class="form-group my-1">
                    <strong >Last name<span class="star">*</span></strong>
                    <input type="text" class="form-control" v-model="lastName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 ">
                <div class="form-group my-1">
                    <strong >Age in years<span class="star">*</span></strong>
                    <input type="number" class="form-control" v-model="age">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group my-1">
                    <strong >Gender<span class="star">*</span></strong>
                    <div>
                        <select class="form-control" v-model="gender">
                        <option value="1" disabled >Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="wife">Wife</option>
                            <option value="husband">Husband</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    <div class="row">
    <div class="col-12 ">
        <div class="form-group my-1">
        <strong >Relationship with user<span class="star">*</span></strong>
    <select class="form-control" v-model="relation">
            <option value="1" disabled >Select</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Son">Son</option>
            <option value="Daughter">Daughter</option>
            <option value="wife">Wife</option>
            <option value="husband">Husband</option>
        </select>
        </div>
    </div>
    </div>
    <div class="mt-3">
        <button class="btn btn-blue-color text-white" :disabled="!isFormFamilyInvalid" @click="saveFamilyData()">Create</button>
     </div>
    </div>
    </div>
	</div>
  </div>
                <!-- Update Family  -->
  
  <div id="popupUpdate" class="overlay" v-if="isUpdateFamilyUser">
	<div class="popup">
        <div>
        <div class="fam-title">
            <b>Edit Family</b>
            <a class="close" @click="closeUpdateForm" >&times;</a>
        </div>
        <div class="fam-popup">
            
            <div class="row">
            <div class="col-12 ">
                <div class="form-group my-1">
                    <strong >First name<span class="star">*</span></strong>
                    <input type="text" class="form-control" ref="firstName" v-model="updateData.firstName">
                </div>
            </div>
            <div class="col-12 ">
                <div class="form-group my-1">
                    <strong >Last name<span class="star">*</span></strong>
                    <input type="text" class="form-control"  ref="lastName" v-model="updateData.lastName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 ">
                <div class="form-group my-1">
                    <strong >Age in years<span class="star">*</span></strong>
                    <input type="number" class="form-control"  ref="age" v-model="updateData.age">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group my-1">
                    <strong >Gender<span class="star">*</span></strong>
                    <div>
                        <select class="form-control" name="gender" id="gender"  ref="gender" v-model="updateData.gender">
                        <option value="1" disabled >Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
    <div class="col-12 ">
        <div class="form-group my-1">
        <strong >Relationship with user<span class="star">*</span></strong>
        <div v-if="updateData.relation">
            <select class="form-control"  ref="relation"  v-model="updateData.relation">
            <option value="1" disabled >Select</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Son">Son</option>
            <option value="Daughter">Daughter</option>
            <option value="Others">Others</option>
        </select>
        </div>
        <div v-else>
            <input type="text" class="form-control" :disabled="true" v-model="userRelation">
        </div>
        </div>
    </div>
    </div>
    <div class="mt-3">
        <button class="btn btn-blue-color text-white" :disabled="!isFormFamilyInvalidUpdate" @click="updateFamilyAndUser(updateData)">Save</button>
     </div>
            </div>
            </div>
            </div>
            </div>
    <div class="mt-3">
        <a  href="#popup1">
    <button @click="getFamilyForm() " class="btn btn-blue-color text-white addnewbtn ">Add new member </button>
        </a>
    </div>
        <div class="hr-border my-3"></div>
        <div class="family w-100 ">
            <div class="row">
        <div class="col-12 col-sm-6 col-lg-4 col-md-4 ">
            <legend class="subHeadingText ">My family address</legend>
        </div>
        <div class="col-6 col-lg-4 col-md-4 d-none d-lg-block">
            <button class="expand-toggle-btn" v-if="addressData.length" @click="showFamilyAddress()" role="button">{{ this.showAddress ? "Close all" : "Expand all" }}</button>
        </div>
    </div>
     <!-- Get family address desktop and tablet  -->
    <div class="d-none d-lg-block">
            <div class="content mt-5 " >

          <div class="three" v-for="(address,index) in addressData" :key="index"> 
      <div class="top" >
        <div class="title">Address -{{index +1 }}</div>
      </div>
      <a  href="#popupAddressupdate">
    <transition name="slide" class="list">
      <div class="expandable-fam-address" @click="getAddressData(address)"  v-if="showAddress">    
        <div  class="details" >
        <div class="one">
            <span><strong>Door No: </strong>{{ address.door_no }} </span>
          </div>
          <div class="one">
            <span><strong>Street : </strong>{{ address.street }} </span>
          </div>
          <div class="one">
            <span><strong>Landmark : </strong><span>{{ address.landmark }}</span></span>
          </div>
          <div class="one">
            <span><strong>City : </strong><span>{{ address.city }} </span></span>
          </div>
          <div class="one">
            <span><strong>District : </strong><span>{{ address.district }} </span></span>
          </div>
          <div class="one">
            <span><strong>State : </strong><span>{{ address.state }} </span></span>
          </div>
          <div class="one">
            <span><strong>Pincode : </strong><span>{{ address.pincode.id }} </span></span>
          </div>
          <div class="one">
            <span><strong>Contact no : </strong><span>{{ address.contact_no }} </span></span>
          </div>
        </div>
       </div>
    </transition>
</a>
      </div>
    </div>
    </div>
    <div class="d-block d-lg-none p-2">

          <!-- Get Address Mobile view -->
          <div class="accordion" id="accordionFlushExample">
  <div class="accordion-item" style="border:none" v-for="(address, index) in addressData" :key="index">
    <h2 class="accordion-header" :id="'flush-heading' + index">
      <button class="accordion-button collapsed border-color" style="border-radius: 5px;" type="button" data-bs-toggle="collapse"  :data-bs-target="'#flush-collapse' + index" :aria-expanded="activeAccordion === index ? 'true' : 'false'" >
        Address - {{ index + 1 }}
      </button>
    </h2>
    <div :id="'flush-collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'flush-heading' + index" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <fieldset class="curve-box">
                    
                    <div class="row my-2">
                        <div class="col-12">
                            <label>Door No</label>
                            <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                                v-model="address.door_no">
                        </div>
                       
                    </div>
                    <div class="row my-2">
                    <div class="col-12">
                            <label>Street</label>
                            <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                                v-model="address.street">
                        </div>
                        </div>
                    <div class="row my-2">
                        <div class="col-6">
                            <label>Landmark </label>
                            <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                                v-model="address.landmark">
                        </div>
                        <div class="col-6">
                            <label>City </label>
                            <input type="gender" class="form-control cursor UpdateTextAlignment" :disabled="true"
                                v-model="address.city">
                        </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-12 col-md-6 col-lg-6">
                            <label>District </label>
                            <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                                v-model="address.district">
                        </div>
                    </div>
                    <div>
                        <div class="row my-2">
                        <div class="col-12 col-md-6 col-lg-6">
                            <label>State</label>
                            <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true"
                                v-model="address.state">
                        </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-6 ">
                            <label>Pincode</label>
                            <input type="number" class="form-control cursor UpdateTextAlignment" :disabled="true"
                                v-model="address.pincode.id ">
                        </div>
                    <!-- </div> -->
                    <!-- <div class="row my-2"> -->
                        <div class="col-6 ">
                            <label>Contact no</label>
                            <input type="number" class="form-control cursor UpdateTextAlignment" :disabled="true"
                                v-model="address.contact_no">
                        </div>
                    </div>
                    </div>
                    </fieldset>
      </div>
    </div>
  </div>
</div>
      </div>
        <!-- Add Family Address  -->
    <div class="mt-3">
        <a  href="#popup2">
        <button @click=" getAddressForm()" class="btn btn-blue-color text-white">Add new address</button>
    </a>
    </div>
    
    <div id="popup2" class="overlay" v-if="isAddNewAddress">
	<div class="popup">
        <div>
            <div class="fam-title">
                <b>Add Address</b>
                <a class="close" @click="closeAddressCreateForm">&times;</a>
            </div>
            <div class="fam-popup" >
        <div class="row">
        <div class="col-6 ">
            <div class="form-group my-1">
                <label >Door no<span class="star">*</span></label>
                <input type="text" class="form-control" v-model="door_no">
            </div>
        </div>
        <div class="col-6">
            <div class="form-group my-1">
                <label >Street <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="street">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group my-1">
                <label >City <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="city">
            </div>
        </div>
        <div class="col-6">
            <div class="form-group my-1">
                <label >Landmark <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="landmark">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group my-1">
                <label >District <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="district" >
            </div>
        </div>
        <div class="col-6">
            <div class="form-group my-1">
                <label >State <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="state">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group my-1">
                <label >Pincode <span class="star">*</span></label>
                <input type="number" class="form-control" v-model="pincode" >
            </div>
        </div>
        <div class="col-6">
            <div class="form-group my-1">
                <label >Contact no <span class="star">*</span></label>
                <input type="number" class="form-control" v-model="contact_no">
            </div>
        </div>
    </div>
    <div class="mt-3">
        <button class="btn btn-blue-color text-white" @click="saveAddressFamilyData()"
        :disabled="!isFormInvalid" >Create</button>
    </div>
        </div>
        </div>
    </div>
    </div>  
     <!-- Update family address  -->
    <div id="popupAddressupdate" class="overlay" v-if="IsUpdateAddress">
	<div class="popup">
        <div>
        <div class="fam-title">
            <b>Edit Address</b>
            <a class="close" @click="closeAddressUpdateForm" >&times;</a>
        </div>
        <div class="fam-popup">
        <div class="row">
        <div class="col-6 ">
            <div class="form-group my-1">
                <label >Door no<span class="star">*</span></label>
                <input type="text" class="form-control" v-model="updateAddressData.door_no">
            </div>
        </div>
        <div class="col-6">
            <div class="form-group my-1">
                <label >Street <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="updateAddressData.street">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group my-1">
                <label >City <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="updateAddressData.city">
            </div>
        </div>
        <div class="col-6">
            <div class="form-group my-1">
                <label >Landmark <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="updateAddressData.landmark">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group my-1">
                <label >District <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="updateAddressData.district" >
            </div>
        </div>
        <div class="col-6">
            <div class="form-group my-1">
                <label >State <span class="star">*</span></label>
                <input type="text" class="form-control" v-model="updateAddressData.state">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group my-1">
                <label >Pincode <span class="star">*</span></label>
                <input type="number" class="form-control" v-model="updateAddressData.pincode.id" >
            </div>
        </div>
        <div class="col-6">
            <div class="form-group my-1">
                <label >Contact no <span class="star">*</span></label>
                <input type="number" class="form-control" v-model="updateAddressData.contact_no">
            </div>
        </div>
    </div>
    <div class="mt-3">
        <button class="btn btn-blue-color text-white" @click="updateAddressFamilyData()"
        :disabled="!isFormInvalidUpdateAddress" >Save</button>
    </div>
        </div>
    </div>
        </div>
    </div>
        </div>
    </div>


</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            isShow:false,
            isAddNewMember: false,
            isAddNewAddress: false,
            userPayload: {},
            familyDetails: [],
            familyRecord: [],
            UserRecordId: [],
            famData: [],
            userRecords: {},
            displayValues: {},
            
            famAddress:[],
            familyAddress:[],
            addressData:[],
            taskIndex:"",
            door_no: "",
            landmark: "",
            pincode: "",
            state: "",
            contact_no: "",
            street:"",
            district:"",
            showFamily:false,
            showAddress:false,
            relation: '1',
            gender:'1',
            firstName:"",
            lastName:"",
            age:"",
            updateData:{},
            userRelation:'Self',
            updateAddressData:{},
            IsUpdateAddress:false,
            isUpdateFamilyUser:false
        }
    },
    created: function () {
        this.getFamilyData();
        this.getUserDetail();
        this.getAddress()

    },
    computed: {
        isFormInvalid() {
        const doorNumberRegex = /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z0-9/\\]*$|\d+/;
        const textNumRegex = /^[A-Za-z0-9.\s]*[A-Za-z][A-Za-z0-9.\s]*$/;
        const nameRegex = /^[A-Za-z.\s]+$/;
        const pincodeValidation = /^[1-9](\s*\d){5}$/;
        const mobilevalidation = /^[6-9]\d{9}$/;
      return (
        
        doorNumberRegex.test (this.door_no) &&
        textNumRegex.test(this.street)&&
        textNumRegex.test(this.landmark) &&
        nameRegex.test(this.city)&&
        nameRegex.test(this.state)&&
        nameRegex.test(this.district)&&
        pincodeValidation.test(this.pincode)&&
        mobilevalidation.test(this.contact_no)
      );
    },
    isFormInvalidUpdateAddress() {
        const doorNumberRegex = /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z0-9/\\]*$|\d+/;
        const textNumRegex = /^[A-Za-z0-9.\s]*[A-Za-z][A-Za-z0-9.\s]*$/;
        const nameRegex = /^[A-Za-z.\s]+$/;
        const pincodeValidation = /^[1-9](\s*\d){5}$/;
        const mobilevalidation = /^[6-9]\d{9}$/;
      return (
        
        doorNumberRegex.test (this.updateAddressData.door_no) &&
        textNumRegex.test(this.updateAddressData.street)&&
        textNumRegex.test(this.updateAddressData.landmark) &&
        nameRegex.test(this.updateAddressData.city)&&
        nameRegex.test(this.updateAddressData.state)&&
        nameRegex.test(this.updateAddressData.district)&&
        pincodeValidation.test(this.updateAddressData.pincode.id)&&
        mobilevalidation.test(this.updateAddressData.contact_no)
      );
    },
    isFormFamilyInvalid() {
    const nameRegex = /^[A-Za-z.\s]+$/;
    return (
        nameRegex.test(this.firstName) &&
        nameRegex.test(this.lastName) &&
        this.age !== ""&&
        this.age <= 999 &&
        this.gender !== "1" && 
        this.gender .trim() !== ""&&
        this.relation !== "1" && 
        this.relation.trim() !== ""
    );
},
isFormFamilyInvalidUpdate() {
    const nameRegex = /^[A-Za-z.\s]+$/;
    return (
        nameRegex.test(this.updateData.firstName) &&
        nameRegex.test(this.updateData.lastName) &&
        this.updateData.age !== ""&&
        this.updateData.age <= 999 &&
        this.updateData.gender !== "1" && 
        this.updateData.gender !== "" &&
        (this.updateData.relation !== "1" &&  this.updateData.relation !== "" )|| (!this.userRelation)
    );}
    },
    methods: {

        showFamilyAddress(){
 this.showAddress=!this.showAddress
        },
        showMe(){
            this.showFamily=!this.showFamily
        },

        getDisplayValue(index) {
      return `${index.users.id} - ${1+ index.memberCount}`;
    },
    getDisplayPublicValue(index) {
      return `${index.id} - 1`;
    },
    updateDisplayValue(index, value) {
      this.$set(this.displayValues, index, value);
    },
    taskManagerIndex(index) {
            this.taskIndex = index;
        },
        GoSetting() {
            this.$router.push({ path: `/user/mobile-setting` });
        },
        getFamilyForm(){
            this.isAddNewMember = true
            this.firstName = "";
            this.lastName = "";
            this.age = "";
            this.gender = "1";
            this.relation = "1";
        },
        getAddressForm(){
            this.isAddNewAddress=true
            this.door_no="",
            this.street="",
            this.landmark="",
            this.city="",
            this.district="",
            this.state="",
            this.pincode="",
            this.contact_no=""
        },
        changePublicFirstName(){
      let name =this.firstName.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    changePublicLastName(){
      let name =this.lastName.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
        async saveFamilyData() {
            
            let familyPayload = {
                firstName: this.changePublicFirstName(),
                lastName: this.changePublicLastName(),
                age: this.age,
                gender: this.gender,
                relation: this.relation,
                users: this.userRecords,
                role: 'family'
            }
         
            this.publicUserID = localStorage.getItem('publicSignUpData');
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .post(`${BASE_API_URL}/family-member/${this.publicUserID}`, familyPayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res?.data) {
                        this.getFamilyData();
                       this. isAddNewMember=false
                       this.firstName = "";
                       this.lastName = "";
                       this.age = "";
                       this.gender = "";
                       this.relation = "";
                        this.role = "";
                        this.users = "";
                        // this.$swal("Your family member added")
                    }
                })
                .catch(function (error) {
                    console.log(error?.res);
                });
        },
        closeCreateForm(){
         this.isAddNewMember=false   
        },
        closeUpdateForm(){
            this.getFamilyData();
            this.getUserDetail();
            this.isUpdateFamilyUser=false
        },

        getUserAndFamilyData(data){
            this.updateData=data
            this.isUpdateFamilyUser=true
            
        },
        updateFamilyAndUser(data){
         
            const updateData = data
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            if(updateData.role === 'family'){
                let familyUpdatePayload = {
                    firstName:this.$refs.firstName.value,
                 lastName:this.$refs.lastName.value,
                 age:this.$refs.age.value,
                 gender:this.$refs.gender.value,
                 relation:this.$refs.relation.value
            };
               axios  .put(`${BASE_API_URL}/family-member/${updateData.id}/update`, familyUpdatePayload)
                 .then((response)=>{
                    if(response.data){
                        this.getFamilyData();
                        this.isUpdateFamilyUser=false
                    }
                 })
            }
            else if(updateData.role === 'user'){
                let userUpdatePayload={
                 firstName:updateData.firstName,
                 lastName:updateData.lastName,
                 age:updateData.age,
                 gender:updateData.gender,
                 relation:this.userRelation
                 };

                axios .put(`${BASE_API_URL}/public-user/${updateData.id}/update`, userUpdatePayload)
                 .then((response)=>{
                    if(response.data){
                        this.getUserDetail();
                        this.isUpdateFamilyUser=false
                    }
                 })
            
            }
            else{
                this.$swal("Error in your update")
            }
        },


        async saveAddressFamilyData() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            let familyAddresspayload = {
                door_no: this.door_no,
                street: this.street,
                landmark: this.landmark,
                city:this.city,
                district:this.district,
                state: this.state,
                pincode: this.pincode,
                contact_no: this.contact_no,
                users: this.userRecords,
                family_id: this.FamilyID,
                role:"family"
            }
            axios
                .post(`${BASE_API_URL}/family-address`, familyAddresspayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res?.data) {
                        this.isAddNewAddress=false
                        this.getAddress();
                        this.door_no="",
                        this.street="",
                        this.landmark="",
                        this.city="",
                        this.district="",
                        this.state="",
                        this.pincode="",
                        this.contact_no=""
                        // this.$swal("Your family address is created")
                    }
                })
                .catch(function (error) {
                    console.log(error?.res);
                });
        },
        closeAddressCreateForm(){
                this.isAddNewAddress=false
        },
        
        getAddressData(data){
            this.updateAddressData=data
            this.IsUpdateAddress=true
        },
        closeAddressUpdateForm(){
            this.getAddress();
            this.IsUpdateAddress=false
        },
        updateAddressFamilyData(){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

            let familyAddressUpdatePayload = {
                door_no:this.updateAddressData.door_no,
                street:this.updateAddressData.street,
                landmark:this.updateAddressData.landmark,
                city:this.updateAddressData.city,
                district:this.updateAddressData.district,
                state:this.updateAddressData.state,
                pincode:this.updateAddressData.pincode.id,
                contact_no:this.updateAddressData.contact_no,
            };
               axios  .put(`${BASE_API_URL}/family-address/${this.updateAddressData.id}/update`, familyAddressUpdatePayload)
                 .then((response)=>{
                    if(response.data){
                        this.IsUpdateAddress=false
                    }
                 })
        },
        async getUserDetail() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.publicUserID = localStorage.getItem('publicSignUpData');
            axios
                .get(`${BASE_API_URL}/public-user/${this.publicUserID}/one`)
                .then((response) => {
                    if (response?.data) {
                        this.userRecords = response.data;
                        this.UserRecordId = response.data?.id;
                        this.communicationStatus = this.userRecords?.communication?.id
                        this.Gender = this.userRecords?.gender?.gender
                        this.primaryRadio = true
                    }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
        },
        getFamilyData() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.publicUserID = localStorage.getItem('publicSignUpData');
            axios
                .get(`${BASE_API_URL}/family-member/${this.publicUserID}`)
                .then((response) => {
                    if (response?.data) {
                        this.famData=[];
                        this.familyDetails = response.data;
                        response.data?.map((data) => {
                            this.FamilyID = data.id;
                            this.FamilyUserId = data.users.id;
                            if (this.FamilyUserId == this.publicUserID) {
                                data.isFamily = true;
                                this.famData.push(data)
                            }
                        })
                        this.Gender = response.data.gender
                        response.data.map((data) => {
                         JSON.parse(data.gender)
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
        },
            getAddress(){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.publicUserID = localStorage.getItem('publicSignUpData');
            axios
            .get(`${BASE_API_URL}/family-address`)
            .then((response)=>{
            if(response.data){
                this.addressData=[];
                this.familyAddress = response.data
                response.data.map((data) => {
                    this.addressId = data.users.id;
                    if (this.addressId == this.publicUserID) {
                        this.addressData.push(data)
                    }
                })
            }
            })
            }
    } 
}

</script>
<style>
.fam-popup{
    padding: 20px;
    background-color: #fff;
}
.fam-title{
    background-color: #5ebec3;
    color: #fff;
    font-size: 25px;
    padding: 10px;
    text-align: center;
}
.pop-curve-box{
    border: 2px solid #00979e;
        border-radius: 20px;
        padding: 15px;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  background: #fff;
  border-radius: 5px;
  width: fit-content;
  position: relative;
}

.popup .close {
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    float: right;
    cursor:pointer;
}
.popup .close:hover {
  color: #000;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

.expand-toggle-btn {
  background-image: linear-gradient(-180deg, #5ebec3 0%, #0a797f 100%);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1rem;
  width: 100%;
  max-width: fit-content;
  border: 0;
  cursor: pointer;
}
.expand-toggle-btn:hover {
  background-image: linear-gradient(-180deg, #5ebec3 0%, #0b484b);
}
.list{
  margin: 0;
  padding: 0;
  transform-origin: top;
  overflow: hidden;
}
.slide-enter, .slide-leave-to{
    transform: scaleY(0);
}
 .details{
    padding: 3px 10px 6px 13px;
 }
.expandable {
  background: #fff;
  overflow: hidden;
  color: #000;   
  height: auto;
  transition: all 500ms ease-in-out;
  animation-name: mymove;
  animation-duration: 800ms;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
 }
 @keyframes mymove {
    from{
        height: 0px;
    }
    to{
  height: 185px;
    }
 }
 .expandable-fam-address{
    background: #fff;
  overflow: hidden;
  color: #000;   
  height: auto;
  transition: all 500ms ease-in-out;
  animation-name: slideAddress;
  animation-duration: 800ms;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
 }
 @keyframes slideAddress {
    from{
        height: 0px;
    }
    to{
  height: 245px;
    }
 }
.star {
    color: red;
    font-size: 20px;
}
.content{
  max-width: 1090px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;  
}
.content .three{
  background: #fff;
  margin-bottom: 30px;
  width: calc(33% - 20px);
  text-align: center;
}
.content .top{
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
  color: #fff;
  padding:12px;
  /* cursor: pointer; */

}

.content .top .title{
 font-size: 20px;
 font-weight: 600;

}

.content div .one{
    margin-top: 6px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

}

.content .top,
.content  button{
  background: #11BCC3;

}


.accordion-collapse .collapse show{
border-radius: 10px;
}
.accordion-button .text-color .border-color{
    border-radius: 10px;
}


.accordion-button{
    padding: 16px 20px;
    margin: 2px;
}
button.accordion-button.collapsed.text-color.border-color {
    border-radius: 10px;
}
.box-shadow2 {
    box-shadow: 0px 0px 0px 1px #00979e, 0px 0px 0px 0px #00979e !important;
}
.cardBorder {
  padding: 10px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 20px;
}
.form-control.cursor {
    cursor: not-allowed;
}
.UpdateTextAlignment {
    text-align: left !important;
}
 @media (max-width:1000px) {
   .content .three{
    background: #fff;
    width: calc(50% - 20px);
    margin-bottom: 30px;
}
}
@media screen and (max-width:991px) {
    button.addnewbtn {
        margin-top: 15px;
    }

    .mobileStylePublic {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 6rem;
    }

    .myfamilyMobile {
        margin-left: 20px;
        /* margin-bottom: 10px; */
        margin-top: 1rem;
        color: #000;
        font-weight: 550;
        font-size: 21px;
    }

    fieldset.curve-box {
        border: 2px solid #00979e;
        border-radius: 20px;
        padding: 15px;
        /* margin-top: 1.5rem; */
    }

    .curve-box-mobile{
        border: 2px solid #00979e;
        border-radius: 20px;
        padding: 15px;
    }

    legend.subHeadingText {
        background: #fff;
        padding: 0px 10px;
        margin-bottom:0px ;
        display: block;
        float: none;
        width: auto;
        font-size: 20px;
    }
}
@media (min-width: 768px) {
  .expand-toggle-btn {
    padding: 1rem 1rem;
  }
}
@media (max-width:715px) {
 .content{
    width: 100%;
}
}
@media screen and (max-width:576px) {
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>